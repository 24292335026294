import zoom from '@/components/popups/zoom.vue'
import property from '@/components/popups/property.vue'
import inquiry from '@/components/popups/inquiry.vue'
import inquirySuccess from '@/components/popups/inquiry-success.vue'
import inquiryValidation from '@/components/popups/inquiry-validation.vue'

export default {
  zoom,
  property,
  inquiry,
  inquirySuccess,
  inquiryValidation,
}
