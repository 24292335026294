import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'

import viteSSR, { ClientOnly } from 'vite-ssr/vue'

import VueGtag from 'vue-gtag'
// @ts-ignore
import { PluginSchemaOrg } from '@unhead/schema-org/vue'
// @ts-ignore
import InstantSearch from 'vue-instantsearch/vue3/es'
import VueClickAway from 'vue3-click-away'

import { plugin as popup } from '@/modules/popup'
import { plugin as i18n } from '@/modules/i18n'
import { plugin as sentry } from '@/modules/sentry'
import { plugin as fa } from '@/modules/fontawesome'

import '@/assets/styles/main.css'

import App from './App.vue'
import routes from './routes'

export default viteSSR(App, { routes, pageProps: { passToPage: false } }, (context) => {
  const { app, router, isClient } = context

  app.use(createPinia())

  const head = createHead({ plugins: [PluginSchemaOrg()] })
  app.use(head)

  app.use(i18n)
  app.use(fa)

  app.use(VueClickAway)

  if (isClient) {
    app.use(popup)
    app.use(sentry)
    app.use(
      VueGtag,
      {
        appName: 'Agents App',
        pageTrackerScreenviewEnabled: true,
        config: {
          id: import.meta.env.VITE_GA_MEASUREMENT_ID,
        },
      },
      router
    )
    app.use(InstantSearch)
  }

  app.component(ClientOnly.name, ClientOnly)

  return { head }
})
