<script setup lang="ts">
import { ref, onMounted } from 'vue'

const code = ref('')
const codeInput = ref<HTMLInputElement | null>(null)
const selectedIndex = ref(0)

const emit = defineEmits(['change'])

function changeCode(event: Event) {
  if (event.target === null) return

  const eventTarget = <HTMLInputElement>event.target

  // clear input if user attempt to remove any digit
  if ((<InputEvent>event).inputType === 'deleteContentBackward') {
    eventTarget.value = ''
  }

  // keep only numbers
  eventTarget.value = eventTarget.value.replace(/[^0-9]/, '')

  if (codeInput.value === null) return

  // if the cursor was not at the begining of the code, we want to replace
  // the currently selected number instead of adding a digit between existing numbers
  const selectionStart = <number>codeInput.value.selectionStart
  if (selectionStart) {
    eventTarget.value =
      eventTarget.value.substring(0, selectionStart) +
      eventTarget.value.substring(selectionStart + 1)
  }

  // we set the currently selected digit to the next one (sync with real input)
  selectedIndex.value = selectionStart

  // if there is more digits than expected, we trim the last ones
  if (eventTarget.value.length > 4) {
    eventTarget.value = eventTarget.value.substring(0, 4)
  }

  // if there is exactly 4 digits, we remove the focus to remove the keyboard on mobile
  if (eventTarget.value.length === 4) {
    selectedIndex.value = 4
    codeInput.value.blur()
  }

  // we finally store the code to be used in the next submit
  code.value = eventTarget.value

  // this cause some troubles on some devices that places the input on top of the page
  // codeInput.value.scrollIntoView()

  // if (isRequestFailed.value) {
  //   store.clearError()
  // }

  emit('change', code.value)
}

function focus(index: number) {
  selectedIndex.value = index
  if (codeInput.value === null) return

  codeInput.value.focus()
  codeInput.value.selectionStart = index
  codeInput.value.selectionEnd = index

  selectedIndex.value = codeInput.value.selectionStart
}

onMounted(function () {
  if (codeInput.value === null) return
  codeInput.value.focus()
})
</script>

<template>
  <div class="code">
    <input
      type="text"
      pattern="[0-9]*"
      inputmode="numeric"
      id="code"
      autocomplete="one-time-code"
      ref="codeInput"
      @input="changeCode"
    />
    <span
      class="digit"
      v-for="index in [0, 1, 2, 3]"
      :key="index"
      @click="focus(index)"
      :class="{ selected: selectedIndex === index }"
      >{{ code.toString()[index] }}</span
    >
  </div>
</template>

<style scoped>
.code {
  display: flex;
  position: relative;
}

p.error {
  color: var(--cl-red);
  margin-top: 12px;
}

.code input {
  background-color: transparent;
  color: transparent;
  opacity: 0;
  height: 0px;
  z-index: 0;
  width: 1px;
}

.code .digit {
  width: 48px;
  height: 60px;
  margin: 12px 6px 16px;
  border: 1px solid var(--cl-powder-gray);
  border-radius: 2px;
  text-align: center;
  font-size: 32px;
  z-index: 1;
  background-color: var(--cl-white);
  line-height: 58px;
}

.code.error .digit {
  border: 1px solid var(--cl-red);
}

.code .digit.selected {
  border: 1px solid var(--cl-green);
}

.code input::-webkit-outer-spin-button,
.code input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.code input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
