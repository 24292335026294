// ------------------
// Imports
// ------------------

import { h, reactive } from 'vue'
import type { Plugin } from 'vue'

import { event } from 'vue-gtag'

import Config from '@/configs/popups'

// ------------------
// Types
// ------------------

type Key = keyof typeof Config

// ------------------
// Helpers
// ------------------

function display(value: boolean) {
  return {
    display: value ? '' : 'none',
  }
}

// ------------------
// State
// ------------------

const State = reactive({
  opened: [] as { key: Key; props: any; resolve: (value: unknown) => void }[],
  active: -1,
})

// ------------------
// Popup
// ------------------

const Popup = {
  show(key: Key, props?: any) {
    return new Promise((resolve) => {
      State.active = State.opened.push({ key, props, resolve }) - 1
      event('popup_display', { id: key })
    })
  },

  hide(param?: any, index = State.opened.length - 1) {
    const currentPopup = State.opened[index]
    param !== undefined && currentPopup.resolve(param)
    State.opened.splice(index, 1)
    State.active = State.opened.length - 1
    event('popup_close', { id: currentPopup.key })
  },

  clear() {
    State.active = -1
    State.opened = []
  },

  get isActive() {
    return State.active !== -1
  },

  get opened() {
    return State.opened.map((o) => o.key)
  },
}

// ------------------
// Plugin
// ------------------

export const plugin: Plugin = {
  install(app) {
    // set actions globally

    app.config.globalProperties.$popup = Popup

    // <popup-view> component

    app.component('PopupView', {
      props: {
        tag: {
          type: String,
          default: 'div',
        },
      },

      render() {
        return h(
          (this as any).$props.tag, // TODO
          { style: display(Popup.isActive) },
          State.opened.map((popup, index) => {
            return h(Config[popup.key], {
              ...popup.props,
              style: display(index === State.active),
            })
          })
        )
      },
    })
  },
}

// ------------------
// Exports
// ------------------

export default Popup

// ------------------
// Augmentation
// ------------------

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $popup: typeof Popup
  }
}
