import { createI18n } from 'vue-i18n'
import messages from '@/configs/i18n'

const i18n = createI18n({
  legacy: false,
  locale: 'ja',
  messages,
  fallbackWarn: false,
  missingWarn: false,
})

export default i18n
export const plugin = i18n
