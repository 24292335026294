import { reactive } from 'vue'

// -------------------
// Types
// -------------------

type Level = 'info' | 'warn' | 'error'
interface Config {
  type?: Level
  message: string
  duration?: number
  onlyIfNotShown?: boolean
}

// -------------------
// Toast class
// -------------------

export class Toast {
  timeout: ReturnType<typeof setTimeout> | undefined
  id: number
  message: string
  duration: number
  type: Level
  onlyIfNotShown: boolean
  startedAt: number = 0
  cb?: () => void

  constructor(options: Config) {
    this.message = options.message
    this.duration = options.duration ?? 3000
    this.type = options.type ?? 'info'
    this.onlyIfNotShown = options.onlyIfNotShown ?? false
    this.id = Date.now()
  }

  wait(_cb: (t: Toast) => void) {
    this.cb = () => _cb(this)
    this.startedAt = Date.now()
    this.timeout = setTimeout(this.cb, this.duration)
  }

  pause() {
    clearTimeout(this.timeout)
  }

  resume() {
    if (this.cb != undefined) {
      const left = this.duration - (Date.now() - this.startedAt)
      if (left > 0) this.timeout = setTimeout(this.cb, left)
      else this.cb()
    }
  }
}

// -------------------
// API
// -------------------

const queue: Toast[] = reactive([])

function notify({ replace = false, ...options }: Config & { replace?: boolean }) {
  if (replace) queue.unshift(new Toast(options))
  else queue.push(new Toast(options))
}

function info(message: string) {
  notify({ message, type: 'info' })
}

function warn(message: string) {
  notify({ message, type: 'warn' })
}

function error(message: string, onlyIfNotShown = true) {
  notify({ message, type: 'error', duration: 5000, onlyIfNotShown })
}

export default {
  queue,
  info,
  warn,
  error,
}
