<script setup lang="ts">
import { ref, computed } from 'vue'
import { event } from 'vue-gtag'

import Popup from '@/modules/popup'
import { use as useForm } from '@/stores/form'

import itemClose from '@/components/popups/items/close.vue'
import UiValidationCode from '@/components/ui/ValidationCode.vue'

import IconWarn from '@/assets/icons/info.svg?component'

const props = defineProps(['app'])

const code = ref('')
const store = useForm()

function changeCode(newCode: number) {
  if (isRequestFailed.value) {
    store.clearError()
  }

  code.value = newCode.toString()
}

const isRequestFailed = computed(() => {
  return store.result?.code == 'failed'
})

function submit() {
  return store.submit({ app: props.app, code: code.value }).then((resp) => {
    if (resp.code == 'succeeded') {
      event('submit_inquiry_popup')
      Popup.hide()
      return Popup.show('inquirySuccess', {
        link: (store.result as { code: 'succeeded'; shareUrl: string }).shareUrl,
        title: 'ご本人確認が完了しました。',
        message:
          'お客様マイページのご利用が可能になりましたので、物件の閲覧や担当<br/>者とのチャットにご利用ください。',
      })
    }
  })
}
</script>

<template>
  <div class="p-inquiry-validation">
    <item-close @click="$popup.hide()" />

    <h1 class="h2 w7 t-center">担当者へのお問い合わせ</h1>

    <div class="codeContainer t-center">
      <IconWarn />
      <h3 class="h3 w7">まだ完了しておりません</h3>
      <p>
        このメールアドレスに対して物件提案の実績が存在します。配信された<br />自動メールを確認の上、この画面に認証コードを入力してください。
      </p>
      <p class="codeLabel">認証コード</p>
      <ui-validation-code
        @change="changeCode"
        :class="{ error: isRequestFailed }"
        class="u-jc-center"
      />
      <p :class="{ error: isRequestFailed }" v-if="isRequestFailed">
        認証コードに誤りがあるか、期限切れです
      </p>
    </div>

    <a class="btn basic" :class="{ disabled: code.toString().length !== 4 }" @click="submit"
      >次へ</a
    >
  </div>
</template>

<style scoped>
.p-inquiry-validation {
  margin: auto;
  padding: 33px 40px;
  position: relative;
  background: white;
}

.p-inquiry-validation .codeContainer svg {
  width: 40px;
  color: var(--cl-orange);
  margin: auto;
}

.p-inquiry-validation .codeContainer {
  background-color: var(--cl-gray-50);
  padding: 32px 24px;
  margin: 29px 0px 40px;
}

.p-inquiry-validation .codeContainer h3 {
  margin: 8px 0px 16px;
}

.p-inquiry-validation .codeContainer .codeLabel {
  margin: 16px 0px 0px;
}

.p-inquiry-validation p.error {
  color: var(--cl-red);
}

@media (max-width: 750px) {
  .p-inquiry-validation {
    width: 100%;
    border-radius: 0 !important;
  }
}
</style>
