import type { AxiosRequestConfig } from 'axios'

// import { boundsToMinMax } from '@/utils/geo'

interface Config extends Record<string, (...args: any[]) => AxiosRequestConfig> {}

const config: Config = {
  upload(type, file) {
    return {
      method: 'POST',
      url: `/v1/upload/${type}`,
      headers: {
        'Content-Type': file.type,
      },
      data: file,
    }
  },
  meta(path) {
    return {
      method: 'GET',
      url: '/v1/page/meta',
      params: { path },
    }
  },
  list(path, filters, limit, skip) {
    return {
      method: 'GET',
      url: '/v1/page/list',
      params: { path, limit, skip, ...filters },
    }
  },
  agent(id, organizationId) {
    return {
      url: `/v1/agents/${organizationId}/${id}/profiles`,
    }
  },
  agentProperties(id, organizationId, skip) {
    return {
      url: `/v1/agents/${organizationId}/${id}/list`,
      params: { limit: 20, skip },
    }
  },
  property(id) {
    return {
      url: `/v1/property/${id}`,
    }
  },
  me() {
    return {
      url: '/v1/me',
    }
  },
  visitorToken(userId, documentId) {
    return {
      url: '/v1/inquiry/token',
      method: 'POST',
      data: { userId, documentId },
    }
  },
  inquiry(token, form) {
    return {
      url: '/v1/inquiry',
      method: 'POST',
      data: form,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  },
  updateProfile(data) {
    return {
      url: '/v1/profile',
      method: 'POST',
      data,
    }
  },
  refresh(organizationId) {
    return {
      url: '/refresh-token',
      method: 'GET',
      baseURL: import.meta.env.VITE_AUTH_API_ORIGIN,
      params: {
        appId: import.meta.env.VITE_APP_ID,
        organizationId,
      },
      withCredentials: true,
      noRetry: true,
    }
  },
}

export default config
