<script setup lang="ts">
import { ref } from 'vue'
// @ts-ignore
import { PinchZoom3 as itemPinchZoom } from 'pinch-zoom3'
import itemClose from '@/components/popups/items/close.vue'

const props = defineProps(['url', 'wide'])

const img = ref<HTMLImageElement | null>(null)
const width = ref(0)

function onLoad() {
  if (img.value) width.value = img.value.clientWidth
}
</script>

<template>
  <div class="p-zoom u-col">
    <item-close @click="$popup.hide()" />
    <img ref="img" class="u-flex" :src="props.url" @load="onLoad" v-if="!width" />
    <item-pinch-zoom
      class="view u-flex"
      :image="props.url"
      :width="width + 'px'"
      @wheel.prevent
      v-else
    />
  </div>
</template>

<style scoped>
.p-zoom {
  padding-top: 56px;
  background: white;
  max-width: 80%;
  max-height: 90%;
  overflow: hidden;
}
.p-zoom img {
  max-width: 100%;
}
.p-zoom .view {
  background-position: center;
}
@media (max-width: 1060px) {
  .p-zoom {
    max-width: 95%;
    border-radius: 0;
  }
}
</style>
