import { defineStore } from 'pinia'
import { event } from 'vue-gtag'

import Axios from '@/modules/axios'

type InquiryResponse =
  | { code: 'succeeded'; shareUrl: string }
  | { code: 'email_verification_needed' }
  | { code: 'failed'; error: { code: 'INVALID_PHONE' | 'INVALID_EMAIL' } }

export const use = defineStore('form', {
  state: () => ({
    userId: null as number | null,
    organizationId: null as number | null,
    documentId: null as string | null,

    purpose: '' as string,
    notes: '' as string,
    email: '' as string,
    confirmEmail: '' as string,
    phone: '' as string,
    firstName: '' as string,
    lastName: '' as string,
    result: null as InquiryResponse | null,
    requestedAt: '' as string,
  }),
  actions: {
    clearError() {
      if (this.result) this.result.code = 'email_verification_needed'
    },
    submit({ code = '', app = '' } = {}) {
      return Axios.call('visitorToken', this.userId, this.documentId)
        .then(({ data: { token } }) => {
          return Axios.call('inquiry', token, {
            purpose: this.purpose,
            notes: this.notes,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            requestedAt: this.requestedAt,
            code,
            app,
          })
        })
        .then((res) => {
          this.result = res.data as InquiryResponse
          if (this.result.code === 'succeeded') {
            event('inquiry_complete', {
              length: this.notes.length,
              enum: this.purpose,
              type: app,
            })
          } else {
            event('inquiry_error', { enum: this.result.code })
          }
          return this.result
        })
    },
  },
})
