import type { Plugin } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMapMarkerAlt,
  faPhone,
  faLink,
  faChevronLeft,
  faChevronRight,
  faBuilding,
  faUser,
  faHouse,
  faUsers,
  faPencil,
  faGlobe,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import { faImage, faTrashCan } from '@fortawesome/free-regular-svg-icons'

// @ts-ignore
library.add(
  faMapMarkerAlt,
  faPhone,
  faLink,
  faChevronLeft,
  faChevronRight,
  faBuilding,
  faUser,
  faHouse,
  faUsers,
  faImage,
  faTrashCan,
  faPencil,
  faGlobe,
  faCircleXmark
)

export const plugin: Plugin = {
  install(app) {
    app.component('FontAwesomeIcon', FontAwesomeIcon)
  },
}
