import type { MessageContext } from '@intlify/core-base'
export default {
  ja: {
    error: '何かがうまくいかなかったようです。\n時間を置いてもう一度お試しください。',
    type: {
      rent: '賃貸',
      buy: '売買',
      invest: '投資',
    },
    buildingType: {
      condo: 'マンション',
      house: '一戸建て',
      land: '土地',
      income: '収益マンション',
    },
    disclaimers: {
      schoolDistricts:
        '通学区域は、国土数値情報の小中学校区データを基に表示されています。このデータは十分な精度や正確性が確保されていないため、詳細な通学区域を判断する場合は、各自治体までお問い合わせください。また、一部市区町村においては通学区域の範囲データが公開されていないため表示されません。',
    },
    currentLocation: (ctx: MessageContext) => {
      const { prefecture, city, town } = ctx.values
      return [prefecture, city, town].filter((v) => v).join('')
    },
    zoom: '拡大する',
    cancel: 'キャンセル',
    save: '保存',
    nameFallback: (ctx: MessageContext) =>
      `${ctx.named('address')} ${ctx.linked('buildingType.' + ctx.named('type'))}`,
    langs: {
      ja: '日本語',
      en: '英語',
      zh: '中国語',
      ko: '韓国語',
      vi: 'ベトナム語',
      tgl: 'タガログ語（フィリピン）',
      pt: 'ポルトガル語',
      fr: 'フランス語',
    },
  },
  en: {
    error: 'An error occurred. Please try again later.',
    type: {
      rent: 'rental',
      buy: 'sale',
      invest: 'investment',
    },
    buildingType: {
      condo: 'apartment | apartment',
      house: 'house | houses',
      land: 'lot | lots',
    },
    zoom: 'Zoom',
    cancel: 'Cancel',
    save: 'Save',
    nameFallback: (ctx: MessageContext) =>
      `${ctx.named('address')} ${ctx.linked('buildingType.' + ctx.named('type'))}`,
    langs: {
      ja: 'Japanese',
      en: 'English',
      zh: 'Chinese',
      ko: 'Korean',
      vi: 'Vietnamese',
      tgl: 'Tagalog',
      pt: 'Portuguese',
      fr: 'French',
    },
  },
}
