<script setup lang="ts">
import type { Property } from '@/types/properties'
import type { AgentWithProfiles } from '@/types/profiles.d'

const props = defineProps<{ property: Property; agents: AgentWithProfiles[] }>()

function getAvatar(agent: AgentWithProfiles): string {
  return agent.profile.avatar?.data
    ? 'data:image/png;base64,' + agent.profile.avatar.data
    : '/images/avatar.png'
}

function getAddress(agent: AgentWithProfiles): string {
  const company = agent.company.general
  return [company?.prefecture, company?.city, company?.street].filter((v) => v).join('')
}
</script>

<template>
  <div class="p-property u-col">
    <div class="close w5 u-row">
      <button type="button" @click="$popup.hide()">閉じる</button>
    </div>
    <div class="property u-row">
      <img class="u-flex" :src="props.property.images.large.url" />
      <div class="divider" />
      <div class="agents">
        <p class="title h4">この物件を取り扱うエージェント</p>
        <div class="agent u-col" v-for="agent of agents" :key="agent.id">
          <div class="head u-row">
            <div class="avatar" :style="{ backgroundImage: 'url(' + getAvatar(agent) + ')' }" />
            <div class="right u-col">
              <p>{{ agent.company.general?.name }}</p>
              <p class="fullname h4 w7">
                {{ agent.profile.personal?.fullname }}
              </p>
              <p>取扱物件数: x,000</p>
            </div>
          </div>
          <div class="info u-col">
            <div class="row u-row" v-if="agent.company.general?.prefecture">
              <div class="icon">
                <FontAwesomeIcon :icon="['fas', 'map-marker-alt']" />
              </div>
              <span class="u-trunc">{{ getAddress(agent) }}</span>
            </div>
            <div class="row u-row" v-if="agent.company.general?.phone">
              <div class="icon">
                <FontAwesomeIcon :icon="['fas', 'phone']" />
              </div>
              <a class="u-trunc" :href="'tel:' + agent.company.general?.phone">
                {{ agent.company.general?.phone }}
              </a>
            </div>
            <div class="row u-row" v-if="agent.company.general?.website">
              <div class="icon">
                <FontAwesomeIcon :icon="['fas', 'link']" />
              </div>
              <a class="u-trunc" :href="agent.company.general?.website">
                {{ agent.company.general?.website }}
              </a>
            </div>
          </div>
          <button
            type="button"
            class="btn primary"
            @click="$popup.show('inquiry', { app: 'openroom' })"
          >
            この物件に問い合わせする
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.p-property {
  margin: auto;
  padding: 24px;
  background: white;
  border-radius: 12px;
  width: 90%;
  max-height: 90%;
}
.p-property .u-row,
.p-property .u-col {
  align-items: unset;
}
.p-property .close {
  align-self: end;
}
.p-property .property {
  width: 100%;
  margin-top: 24px;
}
.p-property .property img {
  object-fit: scale-down;
}
.p-property .property .divider {
  display: none;
  border-top: solid 1px #c8cfca;
  margin: 24px 0;
}
.p-property .agents {
  overflow-y: auto;
  flex-shrink: 0;
}
.p-property .agents .title {
  margin-bottom: 12px;
}
.p-property .agents .agent {
  width: 280px;
  padding: 12px;
  border-radius: 4px;
}
.p-property .agents .agent + .agent {
  margin-top: 16px;
}
.p-property .agents .agent .head .avatar {
  width: 70px;
  height: 70px;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
}
.p-property .agents .agent .head {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #e6e8e7;
}
.p-property .agents .agent .head .fullname {
  margin: 8px 0;
}
.p-property .agents .agent .info {
  margin-bottom: 16px;
}
.p-property .agents .agent .info .row {
  align-items: center;
  color: #6b7470;
  height: 20px;
}
.p-property .agents .agent .info .row + .row {
  margin-top: 8px;
}
.p-property .agents .agent .info .row .icon {
  width: 20px;
}
.p-property .agents .agent .info .row svg {
  margin-right: 8px;
  fill: #6b7470;
}
.p-property .agents .agent .info .row svg circle {
  fill: var(--cl-white);
}
.p-property .agents .agent button {
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  line-height: unset;
  padding: 12px 16px;
  background: #f39800;
  box-shadow: 0px 2px 10px 2px rgba(46, 212, 122, 0.1);
}
.p-property .view {
  background-position: center;
}
@media (max-width: 1060px) {
  .p-property {
    max-height: unset;
    margin: 16px !important;
    width: 95%;
  }
  .p-property .property {
    flex-direction: column;
  }
  .p-property .property > * {
    width: 100%;
  }
  .p-property .property img {
    object-fit: scale-down;
  }
  .p-property .property .divider {
    display: block;
  }
  .p-property .agents {
    margin: 0;
  }
  .p-property .agents .agent {
    width: 100%;
  }
}
</style>
