import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m19.24 25.39 8.47-8.47a.5.5 0 0 0 0-.71L26.3 14.8a.5.5 0 0 0-.71 0L18 22.38l-3.58-3.58a.5.5 0 0 0-.71 0l-1.41 1.41a.5.5 0 0 0 0 .71l4.47 4.47a1.73 1.73 0 0 0 1.23.51 1.76 1.76 0 0 0 1.24-.51M20 5a15 15 0 1 0 15 15A15 15 0 0 0 20 5m0 33a18 18 0 1 1 18-18 18 18 0 0 1-18 18" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }