<style>
/* main */

.ui-spinner {
  width: 48px;
  height: 48px;
  animation: ui-spinner 1.5s linear infinite;
}
.ui-spinner .spin {
  clear: both;
  width: 100%;
  height: 100%;
  animation: ui-spinner-spin 1.5s ease-in-out infinite;
}

/* sectors */

.ui-spinner .spin div {
  width: 50%;
  height: 50%;
}
.ui-spinner .spin div:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-left: 2px solid var(--cl-blue);
  border-bottom: 2px solid var(--cl-blue);
  border-radius: 0 0 0 100%;
}
.ui-spinner .spin div:nth-child(1):before {
  transform: rotate(90deg);
}
.ui-spinner .spin div:nth-child(2):before {
  transform: rotate(180deg);
}
.ui-spinner .spin div:nth-child(1) {
  transform-origin: 100% 100%;
  animation: ui-spinner-1 1.5s ease-in-out infinite;
}
.ui-spinner .spin div:nth-child(2) {
  transform-origin: 0 100%;
  animation: ui-spinner-2 1.5s ease-in-out infinite;
}

/* animations */

@keyframes ui-spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-270deg);
  }
}
@keyframes ui-spinner-spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-135deg);
  }
  100% {
    transform: rotate(-450deg);
  }
}
@keyframes ui-spinner-1 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes ui-spinner-2 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>

<template>
  <div class="ui-spinner">
    <div class="spin u-clear">
      <div class="u-fl" />
      <div class="u-fl" />
      <div class="u-fl" />
    </div>
  </div>
</template>
