// ------------------
// Imports
// ------------------

import axios, { AxiosError } from 'axios'
import type { AxiosResponse } from 'axios'
import { useRoute } from 'vue-router'
// @ts-ignore
import Retry, { RetryableError } from '@/modules/axios-retry'
import Config from '@/configs/api'
import { plugin as i18n } from '@/modules/i18n'
import Sentry, { instrument as axiosTracing } from '@/modules/sentry'
import toasts from '@/stores/toasts'

// ------------------
// Instance
// ------------------

const Axios = Retry(
  axios.create({
    baseURL: import.meta.env.SSR
      ? import.meta.env.VITE_SSR_API_ORIGIN
      : import.meta.env.VITE_API_ORIGIN,
    withCredentials: true,
  })
)

// ------------------
// Tracing
// ------------------

axiosTracing(Axios)

// ------------------
// Errors
// ------------------

const ERRORS: Record<string, RegExp> = {}

const STATUS_ERRORS: Record<number, string> = {
  500: 'error',
}

class IncompleteResponse extends RetryableError {
  constructor(response: AxiosResponse) {
    super('Incomplete response', response)
  }
}

// ------------------
// Interceptors
// ------------------

Axios.interceptors.response.use((response: AxiosResponse) => {
  const { config, headers } = response
  const value = headers['x-krakend-completed']
  if (value) {
    if ((config as any).complete && value !== 'true') {
      return Promise.reject(new IncompleteResponse(response))
    }
  }
  return response
})

Axios.interceptors.response.use(undefined, (error: Error) => {
  if (
    !import.meta.env.SSR &&
    error instanceof AxiosError &&
    error.response != undefined &&
    error.response.config.url != '/v1/share' &&
    error.response.config.url != '/v1/token' &&
    !(error.response.config as any).final &&
    error.response.status == 401
  ) {
    const route = useRoute()
    const configToken = Config.token(route.query)
    const { config } = error.response
    return Axios(configToken).then(() => Axios({ ...config, final: true }))
  }
  return Promise.reject(error)
})

Axios.interceptors.response.use(undefined, (error: Error) => {
  if (error instanceof AxiosError && error.response != undefined) {
    if (typeof error.response.data == 'object') {
      const data = error.response.data
      const keys = Object.keys(data).filter((k) => k.startsWith('error_'))
      if (keys.length) {
        keys
          .map((k) => data[k])
          .forEach((err) => {
            const body = err.body || err.http_body
            const message = typeof body === 'string' ? body : body.message
            Object.entries(ERRORS).forEach(([key, pattern]) => {
              if (pattern.test(message)) toasts.warn(i18n.global.t(key))
            })
          })
        return Promise.reject(error)
      }
    } else if ('status' in error.response) {
      const { status } = error.response

      const message = STATUS_ERRORS[status]

      if (message || status >= 500) {
        toasts.error(i18n.global.t(message || 'error'))
      }

      if (status >= 500) {
        Sentry.captureException(error)
      }
    }
  }
  return Promise.reject(error)
})

// ------------------
// Canceller
// ------------------

const Canceller = {
  store: [] as AbortController[],

  create() {
    const source = new AbortController()
    this.store.push(source)
    return source
  },

  delete(source: AbortController) {
    const index = this.store.indexOf(source)
    if (index === -1) return
    this.store.splice(index, 1)
  },

  abort(source: AbortController) {
    this.delete(source)
    source.abort()
  },

  reset() {
    this.store.forEach((source) => source.abort())
    this.store.length = 0
  },
}

// ------------------
// Exports
// ------------------

export default {
  call<T = any>(key: string, ...args: any[]): Promise<AxiosResponse<T>> {
    if (!(key in Config)) throw new Error(`No API config found for key ${key}`)
    const config = Config[key](...args)
    const canceller = Canceller.create()
    return Axios({
      ...config,
      signal: canceller.signal,
      headers: config.headers,
    })
  },

  abort() {
    Canceller.reset()
  },
}
