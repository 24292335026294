import * as Sentry from '@sentry/vue'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { AxiosHeaders } from 'axios'

import type { Plugin as VuePlugin } from 'vue'

import type { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios'

export const plugin: VuePlugin = {
  install(app) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.MODE,
      release: import.meta.env.VITE_APP_VERSION,
      integrations: [new TracingIntegrations.BrowserTracing()],
      beforeSend(event, hint) {
        // eslint-disable-next-line no-console
        if (!import.meta.env.SSR) console.error(hint.originalException)
        return event
      },
    })
    app.config.globalProperties.$sentry = Sentry
  },
}

interface TracingRequestConfig extends InternalAxiosRequestConfig {
  external?: boolean
  span?: Sentry.Span
}

export function instrument(axios: AxiosInstance) {
  axios.interceptors.request.use((config: TracingRequestConfig) => {
    if (!config.external) {
      const hub = Sentry.getCurrentHub()
      const scope = hub.getScope()
      const transaction = scope?.getTransaction()

      if (transaction) {
        const span = transaction.startChild({
          op: 'http.outbound',
          data: config,
        })
        config.span = span
        const headers = config.headers ?? (config.headers = new AxiosHeaders({}))
        headers['Traceparent'] = span.toTraceparent()
      }
    }
    return config
  })

  axios.interceptors.response.use(
    (response) => {
      const { span } = response.config as TracingRequestConfig
      if (span) {
        span.setHttpStatus(response.status)
        span.finish()
      }
      return response
    },
    (err: AxiosError) => {
      if (err.response?.config) {
        const { span } = err.response.config as TracingRequestConfig
        if (span) {
          span.setHttpStatus(err.response.status)
          span.finish()
        }
      }
      return Promise.reject(err)
    }
  )
}

export function configureScope() {
  // TODO
}

export default Sentry
